import "./style.css";
import logo from "./img/logo.svg";
import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps";
import { Link, useNavigate } from "react-router-dom";
export default function Footer() {
  const Navigate = useNavigate();
  return (
    <div className="footer">
      <div className="leftFooter">
        <img src={logo} alt="" onClick={() => Navigate("/")} />
        <a href="tel:+84 38 242 5892" className="telFooter">
          +84 38 242 5892
        </a>
        <span>С 8:00 до 19:00</span>
        <p>oba, 18, sk. no:20, 07460 alanya/antalya </p>
        <Link to="/privacy" target="_blank" className="popsf">
          политика конфиденциальности
        </Link>
        <Link to="/agreement" target="_blank" className="popsf">
          согласие с рассылкой
        </Link>
        <Link to="/otkaz" target="_blank" className="popsf">
          отказ от ответственности
        </Link>
        <div>© 2023 C.R. Property</div>
      </div>
      <div className="rightFooter">
        <YMaps>
          <Map
            width={document.body.clientWidth > 640 ? "60vw" : "100vw"}
            height={document.body.clientWidth > 640 ? "37vw" : "100vw"}
            defaultState={{
              center: [36.535153, 32.03881],
              zoom: 17,
            }}
          >
            <Placemark geometry={[36.535153, 32.03881]} />
          </Map>
        </YMaps>
      </div>
    </div>
  );
}

import "./style.css";
import castle from "./img/castle.webp";
import mobcas from "./img/mobcas.webp";
import Slider from "infinite-react-carousel";
export default function Alania({ form, setForm }) {
  return (
    <div className="Alania">
      <h3>
        Почему люди выбирают <br /> жить в Аланье?
      </h3>
      <img src={document.body.clientWidth > 640 ? castle : mobcas} alt="" />
      {document.body.clientWidth < 640 ? (
        <div className="mnUl">
          <Slider dots arrows={false} duration={80}>
            <ul>
              <li>
                <div>♦️</div>
                <p>
                  Здесь намного дешевле, чем в ОАЭ или Испании, а климат и
                  уровень жизни ничуть не хуже.
                </p>
              </li>
              <li>
                <div>♦️</div>
                <p>
                  Доступна оплата недвижимости криптовалютой, в евро и долларах.
                </p>
              </li>
              <li>
                <div>♦️</div>
                <p>
                  г.Алания находиться в сейсмически безопасной "белой зоне".
                </p>
              </li>
              <li>
                <div>♦️</div>
                <p>Страна не участвует в военных и политических конфликтах.</p>
              </li>
            </ul>
            <ul className="cetn">
              <li>
                <div>♦️</div>
                <p>Легкий способ получения ВНЖ за инвестиции в недвижимость.</p>
              </li>
              <li>
                <div>♦️</div>
                <p>
                  Покупка строящегося жилья дает возможность оформить ВНЖ или
                  гражданство Турции
                </p>
              </li>
              <li>
                <div>♦️</div>
                <p>
                  ВНЖ и гражданство Турции дает безвизовый режим в 111 стран
                  мира.
                </p>
              </li>
              <li>
                <div>♦️</div>
                <p>
                  Турция соседствует с Евро союзом и омывается тремя морями.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <div>♦️</div>
                <p>
                  Недвижимость на берегу Средиземного моря с красивейшими
                  пляжами, под голубыми флагами.
                </p>
              </li>
              <li>
                <div>♦️</div>
                <p>
                  Образование на английском языке, институты выдают
                  международные дипломы.
                </p>
              </li>
              <li>
                <div>♦️</div>
                <p>
                  Турция это светская страна с умеренной религиозной позицией.
                </p>
              </li>
              <li>
                <div>♦️</div>
                <p>
                  Многие жители страны говорят на русском, английском и немецком
                  языках.
                </p>
              </li>
            </ul>
          </Slider>
        </div>
      ) : (
        <div className="mnUl">
          <ul>
            <li>
              <div>♦️</div>
              <p>
                Здесь намного дешевле, чем в ОАЭ или Испании, а климат и уровень
                жизни ничуть не хуже.
              </p>
            </li>
            <li>
              <div>♦️</div>
              <p>
                Доступна оплата недвижимости криптовалютой в евро и долларах.
              </p>
            </li>
            <li>
              <div>♦️</div>
              <p>г.Алания находиться в сейсмически безопасной "белой зоне".</p>
            </li>
            <li>
              <div>♦️</div>
              <p>Страна не участвует в военных и политических конфликтах.</p>
            </li>
          </ul>
          <ul className="cetn">
            <li>
              <div>♦️</div>
              <p>Легкий способ получения ВНЖ за инвестиции в недвижимость.</p>
            </li>
            <li>
              <div>♦️</div>
              <p>
                Покупка строящегося жилья дает возможность оформить ВНЖ или
                гражданство Турции
              </p>
            </li>
            <li>
              <div>♦️</div>
              <p>
                ВНЖ и гражданство Турции дает безвизовый режим в 111 стран мира.
              </p>
            </li>
            <li>
              <div>♦️</div>
              <p>Турция соседствует с Евро союзом и омывается тремя морями.</p>
            </li>
          </ul>
          <ul>
            <li>
              <div>♦️</div>
              <p>
                Недвижимость на берегу Средиземного моря с красивейшими пляжами,
                под голубыми флагами.
              </p>
            </li>
            <li>
              <div>♦️</div>
              <p>
                Образование на английском языке, институты выдают международные
                дипломы.
              </p>
            </li>
            <li>
              <div>♦️</div>
              <p>
                Турция это светская страна с умеренной религиозной позицией.
              </p>
            </li>
            <li>
              <div>♦️</div>
              <p>
                Многие жители страны говорят на русском, английском и немецком
                языках.
              </p>
            </li>
          </ul>
        </div>
      )}
      <div className="button" onClick={() => setForm(true)}>
        Выбрать объект недвижимости
      </div>
    </div>
  );
}

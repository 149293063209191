import "./style.css";

import stat from "./img/stat.webp";
import left from "./img/left.webp";
import dia from "./img/dia.webp";
import Slider from "infinite-react-carousel";
import pis from "./img/pis.svg";
import mobPis from "./img/mobPis.svg";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";
import aka from "./img/aka.svg";
import ak from "./img/ak.svg";
export default function Stat({ form, setForm }) {
  const Navigate = (f) => window.open(f, "_blank", "noreferrer");
  return (
    <div className="stat" id="state">
      <div className="statCont">
        <img src={left} alt="" />
        <div className="statRight">
          {/*    <div className="mounth">
            <p>в месяц</p>
            <div>
              <p>
                168 000 €<span>Стоимость объекта</span>
              </p>
              <p className="cce">
                1600 €<span>Выручка от сдачи в аренду</span>
              </p>
              <p>
                19200 €<span>Чистая прибыль</span>
              </p>
            </div>
          </div> */}
          <img
            className="kaka"
            src={document.body.clientWidth > 640 ? aka : ak}
            alt=""
          />
          <img src={stat} alt="" />
        </div>
      </div>
      <div className="statNext">
        <Fade left>
          <p>
            <span>ЖК построен в самом центре города.</span> В пешей доступности
            находится частная больница, автовокзал, школа, детский сад, банки,
            рестораны, аптеки, рыбный рынок, фермерский базар и многое другое.
            Пляж Клеопатра на расстоянии 450 метров, имеет статус самого чистого
            и красивого в Аланье, традиционно получает голубой флаг каждый год.
            Здесь лучший и чистый песок, самая бирюзовая и прозрачная вода
            Средиземного моря.
          </p>
        </Fade>
        <img src={dia} alt="" />
      </div>
      <img
        src={document.body.clientWidth > 640 ? pis : mobPis}
        className="pis"
        alt=""
      />
    </div>
  );
}
